/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'map': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.535.149a1.012 1.012 0 00-.98-.04l-3.63 1.8L5.397.078H5.39L5.325.05l-.12-.03a.908.908 0 00-.43.01c-.027.007-.048.023-.074.032a.993.993 0 00-.16.067c-.03.016-.067.023-.1.041L.55 2.101a.972.972 0 00-.55.886v11.947a.992.992 0 001 .996c.155-.002.31-.04.45-.11l3.63-1.8 5.6 1.862c.254.081.53.06.77-.06l4-1.99a.983.983 0 00.55-.887V.995a.993.993 0 00-.466-.846zM2.005 3.603l2-1v9.717l-2 1V3.603zm4-1.224l4 1.324v9.846l-4-1.324V2.38zm8 9.946l-2 1V3.608l2-1v9.717z" _fill="#898B9B"/>'
  }
})
